import React from 'react'
import { Typography, Box } from '@mui/material'
import Stack from '@mui/material/Stack';


const Content = () => {
  return (
    <div className='mainContent'>
      <Box sx={{mt:6}}>
        <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
          <Typography variant='h2' sx={{textAlign:"center"}}>Pricing</Typography>
          <Typography variant='h6' sx={{mt:8,textAlign:"center",width:'35%',color:'gray'}}>Choose your Factory Maintenance Pro pricing plan.</Typography>
         </Stack>

      </Box>
      <Box sx={{mt:3}}>
      </Box>
        
    </div> 
  )
}

export default Content
