import React from 'react'
import { Box} from '@mui/system';
import {CardContent,Card,Typography,Button, Stack } from '@mui/material';
import { CardActions } from '@mui/material';
const credentials=[
  {
    id: 1,
    title: "Starter",
    heading: "£80",
    subheading: "per user / month",
    description1: "Single-Plant",
    description2: "3 Zones",
    description3: "50 Assets",
    description4: "Preventive Maintenance scheduler",
    description5: "Unlimited Work Orders",
    description7: "Shared Cloud Infrastructure",
    description8: "Automatic security and version updates",
    description9: "Email support",
    view:"contact us"
  },
  {
    id: 2,
    title: "Standard",
    heading: "£120",
    subheading: "per user / month",
    description1: "Single-Plant",
    description2: "Unlimited Zones",
    description3: "Unlimited Assets",
    description4: "Preventive Maintenance scheduler",
    description5: "Unlimited Work Orders",
    description6: "QR Code Asset Tagging",
    description7: "360 VR Zones",
    description8: "Shared Cloud Infrastructure",
    description9: "Automatic security and version updates",
    description10: "Email support",
    view: "contact us"
  },
  {
    id: 3,
    title: "Enterprise",
    heading: "Contact sales",
    subheading: "for pricing",
    description1: "Multi-Plant",
    description2: "Unlimited Zones",
    description3: "Unlimited Assets",
    description4: "Preventive Maintenance scheduler",
    description5: "Unlimited Work Orders",
    description6: "QR Code Asset Tagging",
    description7: "360 VR Zones",
    description8: "Asset Telemetry (IoT)",
    description9: "Dedicated Cloud Infrastructure",
    description10: "Automatic security and version updates",
    description11: "Single sign-on (SSO) with Google, Azure, Facebook and LinkedIn",
    description12: "User Federation (LDAP, Kerberos)",
    description13: "Premium support",
    view:"contact us"
  },
]

const Cards = () => {
  return (
    <div className='card'>
      <Box sx={{ display: 'flex', direction: 'row', maxWidth:1000 , flexWrap: 'wrap', backgroundColor: 'rgb(247, 245, 245)', justifyContent: 'space-between', mx: 'auto','& > :not(style)': { m: 5},}}>
        {
          credentials.map((info)=>(
            <Stack display='flex' direction='row' justifyContent='space-between'>
              <Card key={info.id} sx={{ width: 250, maxHeight:780 }}>
                <Box sx={{backgroundColor:'lightgrey',height:70, display:'flex',alignItems:'center',justifyContent:'center'}}>
                  <CardContent sx={{display:'flex',alignItems:'center',justifyContent:'center'}}>
       
                    <Typography gutterBottom variant="h5" component="div" sx={{textAlign:'center',justifyContent:'center', alignItems: 'center', color:'black',fontWeight:500}}>
                      {info.title}
                    </Typography>
                  </CardContent>
                </Box>
                <Typography variant='h5' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.heading}</Typography>
                <Typography variant='h6' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.subheading}</Typography>
                <CardActions sx={{textAlign:'center',justifyContent:'center', alignItems: 'center', color:'black',fontWeight:500}}>
                  <Button 
                    variant='outlined'
                    size="extra large"
                    textAlign='centre'
                    href={`mailto:info@technosec.net?subject=FMPro - ${info.title} plan subscription&body=Hello,\nI need more details about this plan.\nBest regards`}>{info.view}
                  </Button>
                </CardActions>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description1}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description2}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description3}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description4}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description5}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description6}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description7}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description8}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description9}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description10}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description11}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description12}</Typography>
                <Typography variant='body2' sx={{m:2, textAlign:'center',justifyContent:'center', alignItems: 'center',}}>{info.description13}</Typography>
              </Card>
            </Stack>
          ))
        }
       
      </Box>
      
    </div>
  )
}

export default Cards
